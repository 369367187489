$(function(){

  var wSP = 768;
  var minDisplayHeight = 600;

  /*
    UA mera
  */
  var viewport = document.createElement("meta");
  viewport.setAttribute("name", "viewport");
  console.log('view');
  if (screen.width < wSP) {
    console.log('sp-view');
    viewport.setAttribute("content", "width=device-width, initial-scale=1.0");
  } else {
    console.log('pc-view');
    viewport.setAttribute('content', 'width=1200');
  }
  document.getElementsByTagName("head")[0].appendChild(viewport);

  /*
    初期設定
  */
  //TOPメインビジュアルサイズ調整
  var h = $(window).height();
  if(minDisplayHeight < h ){
    $('#mainvisual').height(h);
  }
  //ウインドウサイズ変更によっての調整
  $(window).resize(function() {
    //ウインドウの高さを変数に格納
    var w = $(window).width();
    var h = $(window).height();

    if(minDisplayHeight < h ){
      $('#mainvisual').height(h);
    }
  })
  //グローバルメニューサイズ調整
  $('.nav-link>span').each(function () {
    var g_li_w = $(this).width();
    $(this).parent().width(g_li_w);
  });
  // scrool fade
  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      var delay = $(this).data('delay');
      if(delay){
        $(this).delay(delay).queue(function(){
          $(this).stop().addClass("is-show");
        })
      }else{
        $(this).stop().addClass("is-show");
      }
    } else {
      //$(this).stop().removeClass("is-show");
    }
  });

  $(".inview-line").on("inview", function (event, isInView) {
      console.log('ani-view-start');
      $(this).delay('1200').queue(function(){
        $('.whoweare__topic__line1').removeClass("d-none");
        $('.whoweare__topic__line2').removeClass("d-none");
      })
  });

  // ドロップダウンメニューをクリック時閉じる。
  $('.navbar-nav .dropdown-item').on('click', function(){
    if(this.id != 'navbarDropdown'){
      $('.navbar-nav .dropdown-menu').collapse('hide');
      $(".navbar").removeClass("nav-open");
    }
  });
  $('.navbar-nav-sp__list>li .navbar-nav-sp__list__item__list__item').on('click', function(){
    if(this.id != 'navbarDropdown'){
      $('.navbar-collapse').collapse('hide');
      $(".navbar").removeClass("nav-open");
    }
  });
  // ハンバーガーメニュー開閉時
  $('.navbar-toggler').on('click', function(){
    if($(this).hasClass('collapsed')){
      $(".navbar").addClass("nav-open");
    } else {
      $(".navbar").removeClass("nav-open");
    }
  });


  //sm用テキストアコーディオン
  var AcoddionTextBtn = $('.acoddion-text__sm__btn');
  //var AcoddionTextBody = $('.acoddion-text__sm__body');
  AcoddionTextBtn.on('click', function () {
    //上の要素を取得
    var AcoddionTextBody = $(this).prev(".acoddion-text__sm__body");

    if (!$(this).hasClass('open')) {
      AcoddionTextBody.slideDown(200);
      $(this).addClass('open');
    } else {
      AcoddionTextBody.slideUp(200);
      $(this).removeClass('open');
    }
    return false;
  });


  // topic slider
  $('.mainvisual__slider__list').bxSlider({
    easing: 'easeOutBounce',
    //minSlides: 1,
    // maxSlides: 2,
    slideWidth: 2000,
    ticker: true,//tickerオプション、デフォルトはfalse
    speed: 65000,
  });





  // $('.topics__slider').bxSlider({
  //   minSlides: 1,
  //   maxSlides: 8,
  //   slideWidth: 350,
  //   slideMargin: 20,
  // });

  /*
    デバイスサイズによって処理を分岐する
  */
  if (window.matchMedia('(max-width: 576px)').matches) {
    //SP時はスライダーにする。
    $('.products__pickup__list').bxSlider({
      minSlides: 1,
      maxSlides: 2,
      slideWidth: 300,
      slideMargin: 20,
    });
    $('.mission__project__list').bxSlider({
      minSlides: 1,
      maxSlides: 2,
      slideWidth: 300,
      slideMargin: 20,
    });
  } else if (window.matchMedia('(max-width: 768px').matches) {
    //tablet
    $('.mission__project__list').bxSlider({
      controls: false,
      minSlides: 1,
      maxSlides: 2,
      slideWidth: 300,
      slideMargin: 20,
    });
  } else {
    // PC
  }



  $("a.contact-form-link").fancybox({
    toolbar  : false, // ツールバーの非表示
    smallBtn : false,
  });
  $('.contact-form__close').click(function(){
    parent.$.fancybox.close();
  });

});


//読み込んだらフェードアウト
$(window).on('load', function(){
    $.when(
     $('.loader').delay(2000).fadeOut(800)
    ).done(function(){
      //lower mainvisual アニメーション
      $('.top_cacth').addClass('show');
      var element = $(".top_cacth");
      element.each(function () {
        var text = $(this).text();
        var textbox = "";
        text.split('').forEach(function (t, i) {
          if (t !== " ") {
            if (i < 10) {
              textbox += '<span style="animation-delay:.' + i + 's;">' + t + '</span>';
            } else {
              var n = i / 10;
              textbox += '<span style="animation-delay:' + n + 's;">' + t + '</span>';
            }

          } else {
            textbox += t;
          }
        });
        $(this).html(textbox);
      });

      setTimeout(function(){
        const changeKAI = () => {
          $('.mainvisual__slider__list__item .kai-cover').toggleClass("hide");
          setTimeout(changeKAI, 6000);
        }
        changeKAI();
      },5000);


      //lower mainvisual アニメーション
      $('.lower__mv').addClass('show');
      $(".lower__mv__copy__text__p").addClass('bg-slidein');
      var element = $(".lower__mv__copy__text__p");
      element.each(function () {
        var text = $(this).text();
        var textbox = "";
        text.split('').forEach(function (t, i) {
          if (t !== " ") {
            if (i < 10) {
              textbox += '<span style="animation-delay:.' + i + 's;">' + t + '</span>';
            } else {
              var n = i / 10;
              textbox += '<span style="animation-delay:' + n + 's;">' + t + '</span>';
            }

          } else {
            textbox += t;
          }
        });
        $(this).html(textbox);
      });
      setTimeout(function(){
        EachTextAnimeControl();/* アニメーション用の関数を呼ぶ*/
        //IE11対応
        $(".lower__mv__copy__text__p").css('background-color','#FFF');
        $(".lower__mv__copy__text__p").removeClass('bg-slidein')
      },1000);
    });
});
// 10秒待っても読み込みが終わらない時は強制的にローディング画面をフェードアウト
function stopload(){
    $('.loader').delay(1000).fadeOut(700);
}
setTimeout('stopload()',10000);




function EachTextAnimeControl() {
  $('.lower__mv__copy__text__p').each(function () {
    var elemPos = $(this).offset().top - 50;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass("appeartext");

    } else {
      $(this).removeClass("appeartext");
    }
  });
  $('.top_cacth').each(function () {
    var elemPos = $(this).offset().top - 50;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass("appeartext");

    } else {
      $(this).removeClass("appeartext");
    }
  });
}
